import React from "react"
import styles from "./success.module.scss"
import logo from "../../../images/Logo Page.png"
import instagram from "../../../images/winter2022/insta_rounded.svg"
import facebook from "../../../images/winter2022/facebook_rounded.svg"

const Success = ({ location }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={logo} className={styles.logo} />

        {location?.state?.event === "skitrip" ? (
          <>
            <h1> GRACIAS POR PARTICIPAR</h1>

            <h2>
               SORTEAMOS EL <br /> <span> 1 DE AGOSTO </span>
            </h2>
            <p>
              Los ganadores recibirán un correo electrónico con la información
              correspondiente el día 4/08/2022
            </p>
          </>
        ) : (
          <>
            <h1> GRACIAS! </h1>
            <h2>
              Ya estas participando por uno de los lugares <br /> para el Corona
              Sunset.
            </h2>
          </>
        )}

        <div className={styles.social}>
          <img src={facebook} className={styles.facebook} />
          <img src={instagram} className={styles.instagram} />
        </div>
      </div>
    </div>
  )
}

export default Success
