import React, { useEffect, useState } from "react"
import AgeGate2 from "../../components/agegate"
import { Success } from "../../components/Winter2022/Success"

const SuccessPage = ({ location }) => {
  const [age, setAge] = useState(true)

  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
  }, [])

  return !age ? <Success location={location} /> : <AgeGate2 setAge={setAge} />
}

export default SuccessPage
